// TOP.SCSS

@import "functions";
@import "variables";
@import "mixins";

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */
.carousel {
	margin-bottom: 4rem;
	background-color: #8dabcc;
	.container.carousel-inner {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
	}
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
	z-index: 10;
	top: 10rem;
}

/* Declare heights because of positioning of img element */
.carousel-item {
	img {
		display: block;
		margin: 0 auto;
		max-width: 100%;
	}
	.slideimage {
		img {
			max-width: 100%;
		}
	}
	.slidecaption {
		color: #fff;
		max-width: 400px;
		background-color: #4665a2;
		text-align: center;
		padding-top: 9.375rem;
		p.text-s {
			font-size: 1.3125rem;
			margin-bottom: 3.25rem;
		}
		.tagline {
			margin-bottom: 2.125rem;
			span {
				display: block;
			}
		}
		@media (max-width: 991px) {
			min-width: 100%;
			padding-top: 2rem;
			padding-bottom: 2rem;
			p.text-s {
				margin-bottom: 1.5rem;
			}
			.tagline {
				margin-bottom: 1.5rem;
			}
		}
	}
&.ci3 {
		.tagline {
			margin-bottom: .75rem;
		}
	.slidecaption {
		@media (max-width: 991px) {
			p.text-s {
				margin-bottom: .75rem;
			}
			.tagline {
				margin-bottom: .75rem;
			}
		}
	}
}
}

.carousel-control-prev {
	left: 7rem;
}
.carousel-control-next {
	right: 7rem;
}

.carousel-control-prev,
.carousel-control-next {
	width: 5%;
}


.btn-slide {
	border-radius: 0;
	font-size: .9rem;
	font-weight: bold;
	width: 260px;
	line-height: 45px;
	display: block;
	margin: 0 auto;
	color: #3065a7;
	background: #f0fa00 url(../images/common/right_arrow_s_blue.png) right 15px top 50% no-repeat;
	background-color: #f0fa00;
	padding: 0 0 0 0;
	@media (max-width: 991px) {
		margin-bottom: 1rem;
	}

&:hover {
	color: #fff;
	background: transparent url(../images/common/right_arrow_s_white.png) right 15px top 50% no-repeat;
	border-color: #fff;
}
}

/* MAIN LEAD SETTINGS
------------------------------ */
.top-mainlead {
	padding-top: 3.75rem;
	padding-bottom: 3.75rem;
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
	}
	.top-lead {
		text-align: center;
		font-size: 1.125rem;
		font-weight: bold;
		margin-bottom: 2.375rem;
		strong {
			font-weight: bold;
		}
		span {
			display: block;
		}
	}
	h2 {
		text-align: center;
		color: #33669A;
		font-size: 1.375rem;
		margin-bottom: 1.875rem;
	}
}

ul.anker-links {
	padding-left: 0;
	list-style: none;
	max-width: 1080px;
	margin: 0 auto;
	li {
		text-align: center;
		margin-bottom: .25rem;
		width: 100%;
	}
	@media (max-width: 1199px) {
		li {
			max-width: 100%;
			margin: 0 auto .25rem;
		}
		.btn-ankerlink {
			// min-width: 100%;
			min-width: 500px;
		}
	}
	@media (max-width: 530px) {
		li {
			max-width: 100%;
			margin: 0 auto .25rem;
		}
		.btn-ankerlink {
			min-width: 100%;
			// min-width: 500px;
		}
	}
}

.btn-ankerlink {
	border-radius: 0;
	font-size: 1rem;
	font-weight: bold;
	width: 250px;
	line-height: 50px;
	color: #3065a7;
	background: #fff url(../images/common/right_arrow_blue.png) right 20px top 50% no-repeat;
	background-color: #fff;
	border-color: #bebebe;
	border-width: 7px;
	box-sizing: border-box;
	padding: 0 0 0 0;

&:hover {
	color: #3065a7;
	background: #f2f93b url(../images/common/right_arrow_blue.png) right 20px top 50% no-repeat;
	border-color: #f2f93b;
	border-width: 7px;
	box-sizing: border-box;
}
}

/* BUTTON
/* GLAY BORDER / YELLOW BACKGROUND
------------------------------ */
.btn-gb-bgy {
	border-radius: 0;
	font-size: 1rem;
	font-weight: bold;
	width: 250px;
	line-height: 50px;
	color: #3065a7;
	background: #fff url(../images/common/right_arrow_blue.png) right 20px top 50% no-repeat;
	background-color: #fff;
	border-color: #bebebe;
	border-width: 7px;
	box-sizing: border-box;
	padding: 0 0 0 0;

&:hover {
	color: #3065a7;
	background: #f2f93b url(../images/common/right_arrow_blue.png) right 20px top 50% no-repeat;
	border-color: #f2f93b;
	border-width: 7px;
	box-sizing: border-box;
}
}


/* PRODUCTS AND CASES
------------------------------ */
.section-title {
	color: #333;
	font-size: 1.4375rem;
	font-weight: bold;
	text-align: center;
	padding-bottom: 0;
}

.section {
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
		@media (max-width: 1199px) {
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}
}

.top-engineer {
	min-height: 630px;
	padding-top: 2.375rem;
	padding-bottom: 3.875rem;
	h3 {
		margin-bottom: 2.375rem;
	}
	.engineer-links {
		padding-left: 0;
		list-style: none;
		padding-bottom: 26px;
		a {
		&:hover {
			text-decoration: none;
			img {
				opacity: 0.8;
			}
		}
		}
		.eng-link {
			text-align: center;
			img {
				padding-bottom: 10px;
				max-width: 100%;
				max-height: 100%;
				// @media (max-width: 575px) {
				// 	max-width: 90%;
				// 	max-height: 90%;
				// }
			}
			.eng-link-text {
				font-weight: bold;
				text-align: center;
				color: #3065A7;
				margin-bottom: -16px;
			}
			border-bottom: 8px solid #f0fa00;
			@media (max-width: 991px) {
				margin-bottom: 26px;
			}
		}
	}
	.section-note {
		text-align: center;
		margin-bottom: 1.875rem;
		@media (max-width: 991px) {
			text-align: left;
		}
		p {
			margin-bottom: 0;
			line-height: 1.8;
		}
	}
	.goto-page {
		text-align: center;
	}
}

.top-solution {
	min-height: 450px;
	padding-top: 2.375rem;
	padding-bottom: 2rem;
	background-color: #F5F5F5;
	h3 {
		margin-bottom: 2.375rem;
	}
	.solution-links {
		padding-left: 0;
		list-style: none;
		.sol-link {
			text-align: center;
			padding: 8px 8px 30px 8px;
			max-width: 370px;
			margin: 0 auto 2.125rem;
			background-color: #fff;
			img {
				position: relative;
				max-width: 100%;
			}
			a {
			&:hover {
				// opacity: 0.8;
				h4 {
					span {
						background-color: #037;
					}
				}
			}
			}
			h4 {
				position: absolute;
				top: 82px;
				left: 0;
				right: 0;
				margin: auto auto;
				font-size: 1.0625rem;
				font-weight: bold;
				color: #fff;
				span {
					background-color: #4665A2;
					opacity: 0.8;
					display: inline-block;
					height: 40px;
					line-height: 40px;
					padding-left: 2rem;
					padding-right: 2rem;
				}
			}
			p {
				padding-top: 1.5rem;
				margin-bottom: 0;
				span {
					display: block;
				}
			}
		}
	}
}

.top-tags {
	min-height: 390px;
	padding-top: 2.375rem;
	background: #F2F93B url(../images/common/bg_town.png) center bottom repeat-x;
	h3 {
		margin-bottom: 2rem;
	}
	ul {
		padding-left: 0;
		list-style: none;
		max-width: 588px;
		margin: 0 auto;
		li {
			display: inline;
			line-height: 2.0;
			margin-right: 1rem;
			a {
				color: #666;
			}
		}
	}
}

.top-blogs {
	.container {
		padding-left: 100px;
		padding-right: 100px;
		@media (max-width: 991px) {
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}
	min-height: 370px;
	padding-top: 4.375rem;
	padding-bottom: 4.375rem;
	background-color: #fff;
	.top-qanda {
		margin-bottom: 3.75rem;
	}
	.blog-title {
		text-align: right;
		@media (max-width: 991px) {
			text-align: left;
			margin-bottom: 1.5rem;
		}
		padding-right: 2rem;
		border-right: 4px solid #000;
		padding-top: .25rem;
		padding-bottom: .25rem;
		@media (max-width: 575px) {
			border-right: none;
			border-bottom: 4px solid #000;
			.sub-title {
				padding-bottom: .5rem;
			}
		}
		h3 {
			font-size: 1.875rem;
			font-weight: bold;
			margin-bottom: 0;
			letter-spacing: .5rem;
			color: #000;
		}
		.sub-title {
			font-size: .875rem;
			font-weight: bold;
			color: #999;
			margin-bottom: 0;
		}
	}
	.post-list {
		max-width: 100%;
		dl {
			max-width: 100%;
			padding-left: 2rem;
			margin-bottom: 0;
			dt {
				font-size: .9375rem;
			}
			dd {
				font-size: .875rem;
			}
		}
		@media (max-width: 991px) {
			dl {
				padding-left: 0;
			}
		}
		@media (max-width: 575px) {
			dl {
				dd {
					// text-align: right;
					margin-bottom: 1.5rem;
				}
			}
		}
	}
}
